<template>
    <div class="audio-plyr" :style="style">
        <audio ref="audio" :src="src" controls></audio>
    </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

export default {
    props: {
        src: {
            required: true,
        },
        backgroundColor: {
            type: String,
            default: 'white',
        },
    },
    data() {
        return {
            player: null,
        };
    },
    computed: {
        style() {
            return {
                '--plyr-audio-controls-background': this.backgroundColor,
            };
        },
    },
    mounted() {
        this.player = new Plyr(this.$refs.audio, {
            controls: ['play', 'current-time', 'progress', 'mute', 'volume'],
            displayDuration: false,
            invertTime: false,
        });
    },
    methods: {
        togglePlay() {
            return this.player.togglePlay();
        },
        isPlaying() {
            return this.player.playing;
        },
        stop() {
            return this.player.stop();
        },
    },
};
</script>
