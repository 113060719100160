import { v4 as uuidv4 } from 'uuid';
import { randomColor } from '@/functional/randomColor';

export const normalizeMemory = memory => {
    if (Array.isArray(memory.cardSets)) {
        memory.cardSets.forEach(cardSet => {
            if (!cardSet['@id']) {
                cardSet['@id'] = uuidv4();
            }
            if (!cardSet.color) {
                cardSet.color = randomColor();
            }
            if (Array.isArray(cardSet.cards)) {
                cardSet.cards.forEach(card => {
                    if (!card['@id']) {
                        card['@id'] = uuidv4();
                    }
                    card.cardSetColor = cardSet.color;
                });
            } else {
                cardSet.cards = [];
            }
        });
    } else {
        memory.cardSets = [];
    }
};
