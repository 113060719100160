<template>
    <div class="wrapper">
        <div class="stage">
            <slot name="stage"></slot>
        </div>
        <div class="sidebar">
            <div class="sidebar-content">
                <slot name="sidebar"></slot>
            </div>
            <div class="sidebar-footer">
                License: <a href="https://creativecommons.org/licenses/by-nc/4.0/legalcode">CC BY-NC 4.0</a> |
                <a href="https://glarean.mh-freiburg.de/git/digiplay/audio-memory">Version {{ version }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        version() {
            return process.env.VERSION;
        },
    },
};
</script>

<style scoped lang="scss">
    @import "@/scss/import.scss";

    .wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: auto 350px;
    }

    .stage {
        padding: 1rem;
        flex-shrink: 0;
        flex-grow: 1;
    }

    .sidebar {
        background-color: #eee;
        border-left: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        .sidebar-content {
            flex: 1 1 auto;
        }

        .sidebar-footer {
            flex: 0 0 auto;
            padding: .75rem 1rem;
            border-top: 1px solid $border-color;
            font-size: .85rem;
            color: $text-muted;
        }
    }

    @include media-breakpoint-down(sm) {
        .wrapper {
            grid-template-columns: auto 250px;
        }
        .stage {
            padding: .5rem;
        }
    }

    @include media-breakpoint-down(xs) {
        .wrapper {
            display: flex;
            flex-direction: column;
        }
        .sidebar {
            border-left: 0;
        }
    }
</style>
