<template>
    <component :is="tagName"></component>
</template>

<script>
import { CountUp } from 'countup.js';

export default {
    props: {
        value: null,
        tagName: {
            default: 'span',
        },
        options: {
            default: () => {},
        },
    },
    data() {
        return {
            countUp: null,
        };
    },
    mounted() {
        this.countUp = new CountUp(this.$el, this.value, this.options);
    },
    watch: {
        value(value) {
            this.countUp.update(value);
        },
    },
};
</script>
