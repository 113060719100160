import { Deferred } from '@/classes/deferred';

export default class GameCard {

    constructor(card) {
        this.card = card;
        this._flipped = false;
        this._selected = false;
        this._resolved = false;
        this.numberOfAttempts = 0;
        this.cachedAudioFile = null;
        this.preloadDeferred = new Deferred();
        this.preloadAudio(this.audioSrc).then(src => {
            this.preloadDeferred.resolve(src);
            this.cachedAudioFile = src;
        });
    }

    get id() {
        return this.card['@id'];
    }

    get name() {
        return this.card.name;
    }

    get cardSetIri() {
        return typeof this.card.cardSet === 'string' ? this.card.cardSet : this.card.cardSet['@id'];
    }

    toggleSelected() {
        this.selected = !this.selected;
    }

    toggleFlipped() {
        this.flipped = !this.flipped;
    }

    get flipped() {
        return this._flipped;
    }

    set flipped(flipped) {
        this._flipped = !!flipped;
    }

    get selected() {
        return this._selected;
    }

    set selected(selected) {
        if(this._resolved !== true) {
            this._selected = !!selected;
        }
    }

    get resolved() {
        return this._resolved;
    }

    set resolved(resolved) {
        this._resolved = !!resolved;
        if(resolved === true) {
            this._flipped = true;
            this._selected = false;
        }
    }

    get audioSrc() {
        return this.cachedAudioFile || this.card.audioFileUrl;
    }

    async preloadAudio(src) {
        try {
            const response = await fetch(src);
            if(response.ok) {
                const blob = await response.blob();
                return URL.createObjectURL(blob);
            }
        } catch(e) {
            // use not cached audio src if fetch fails
            // (e.g. when served over the filesystem and not a server)
        }
        return null;
    }
}
