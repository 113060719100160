<template>
    <div class="game-card-wrapper">
        <modal class="game-card-modal" :name="`card-modal-${gameCard.id}`" height="100%" :scrollable="true" width="100%">
            <div class="game-card-modal-close-button" @click="hideModal">
                <b-icon icon="x" :font-scale="2"></b-icon>
            </div>
            <h2>{{ gameCard.card.name }}</h2>
            <div class="mb-3">
                <audio-plyr ref="player" :src="gameCard.audioSrc" background-color="var(--light)"></audio-plyr>
            </div>
            <div class="mb-3">
                <p v-if="gameCard.card.description">{{ gameCard.card.description }}</p>
            </div>
            <img v-if="gameCard.card.backImageFileUrl" :src="gameCard.card.backImageFileUrl" class="game-card-modal-image">
        </modal>
        <vue-flip class="game-card" :class="gameCardClassList" width="100%" height="100%" :value="gameCard.flipped" @input="onChangeFlipValue">
            <template v-slot:front class="game-card-face game-card-face-front">
                <div class="game-card-face-wrapper" @click="onClick">
                    <div v-if="number" class="game-card-number">{{ number }}</div>
                    <div class="game-card-face game-card-face-front">
                        <img v-if="gameCard.card.frontImageFileUrl" :src="gameCard.card.frontImageFileUrl" class="game-card-face-image">
                        <div class="game-card-face-body">
                            <div class="game-card-face-body-play-button" @click="onTogglePlay" v-if="gameCard.audioSrc">
                                <b-icon icon="play-fill" :font-scale="2"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:back class="game-card-face game-card-face-back">
                <div class="game-card-face-wrapper" :style="{borderColor: gameCard.card.cardSetColor}">
                    <div class="game-card-face game-card-face-back" @click="showModal">
                        <img v-if="gameCard.card.backImageFileUrl" :src="gameCard.card.backImageFileUrl" class="game-card-face-image">
                        <div class="game-card-face-body">
                            <div class="game-card-face-body-play-button" @click="onTogglePlay" v-if="gameCard.audioSrc">
                                <b-icon icon="play-fill" :font-scale="2"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-flip>
    </div>
</template>

<script>
import VueFlip from 'vue-flip';
import AudioPlyr from '@/components/AudioPlyr';

export default {
    data() {
        return {
            flipped: false,
        };
    },
    components: {
        VueFlip,
        AudioPlyr,
    },
    props: {
        gameCard: {
            type: Object,
            required: true,
        },
        number: {
            type: Number,
            default: null,
        },
    },
    computed: {
        gameCardClassList() {
            return {
                'game-card-selected': this.gameCard.selected,
                'game-card-resolved': this.gameCard.resolved,
            };
        },
    },
    methods: {
        onClick(event) {
            event.stopPropagation();
            this.gameCard.toggleSelected();
            this.$emit('click', this.gameCard);
        },
        onTogglePlay(event) {
            event.stopPropagation();
            this.$emit('toggle-play', this.gameCard);
        },
        onChangeFlipValue() {
            this.$emit('toggle-flipped', this.gameCard);
        },
        showModal(event) {
            event.stopPropagation();
            this.$modal.show(`card-modal-${this.gameCard.id}`);
        },
        hideModal(event) {
            event.stopPropagation();
            this.$modal.hide(`card-modal-${this.gameCard.id}`);
        },
    },
};
</script>

<style scoped lang="scss">

    @import "../scss/import.scss";

    $resolvedCardBorderRadius: 2rem;

    .game-card-modal {
        ::v-deep .vm--modal {
            overflow-y: auto;
            padding: 1rem;
        }

        .game-card-modal-close-button {
            cursor: pointer;
            position: absolute;
            top: .5rem;
            right: .5rem;
        }
    }

    .game-card-modal-image {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        max-height: 400px;
        object-fit: contain;
    }

    .game-card-wrapper {
        height: 100%;
        width: 100%;
    }

    .game-card-face-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: .25rem solid transparent;
        cursor: pointer;
        transition: all .5s;
        background-color: $gray-500;
        padding: 2rem;
    }

    .game-card-number {
        position: absolute;
        bottom: 0;
        right: 0;
        color: rgba(255,255,255,.65);
        text-shadow: 0 0 5px rgba(100, 100, 100, .25);
        font-size: 3rem;
        line-height: 1;
        pointer-events: none;
        z-index: 1;
    }

    .game-card-selected {
        .game-card-face-wrapper {
            box-shadow: 0 0 12px $gray-500;
            border-color: $danger;
            transform: scale(1.1);
        }
    }

    .game-card-resolved {
        .game-card-face-wrapper {
            border-radius: $resolvedCardBorderRadius;
            border-color: $success;
        }
        .game-card-face-image {
            border-radius: $resolvedCardBorderRadius;
        }
    }

    .game-card-face-back .game-card-face-image {
        border-radius: $resolvedCardBorderRadius;
    }

    .game-card-face-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all .5s;
    }

    .game-card-face-body {
        position: relative;
    }

    .game-card-face {
        .game-card-face-body-play-button {
            opacity: 0;
        }

        &:hover .game-card-face-body-play-button {
            opacity: 1;
        }
    }

    .game-card-face-body-play-button {
        position: absolute;
        width: 3rem;
        height: 3rem;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: rgba(0, 0, 0, .5);
        border-radius: 50%;
        transition: all .5s;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border: 1px solid rgba(0, 0, 0, .15);

        &:hover {
            background-color: rgba(255, 255, 255, .5);
            border-color: rgba(255, 255, 255, .15);
            color: $body-color;
            box-shadow: 0 0 5px rgba(0, 0, 0, .3);
        }

        &:active {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, .3);
        }
    }

    .game-card-face {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    ::v-deep .front, ::v-deep .back {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
</style>
