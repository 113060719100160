<template>
    <sidebar-right-layout v-if="memoryGame">
        <template v-slot:stage>
            <div class="row no-gutters">
                <div v-for="(gameCard, index) in memoryGame.gameCards" :key="gameCard.id" class="col-4 col-md-4 col-lg-3 col-xl-2">
                    <div class="m-2 m-md-3">
                        <div class="embed-responsive embed-responsive-1by1 overflow-visible">
                            <div class="embed-responsive-item">
                                <card :gameCard="gameCard" @toggle-play="togglePlayListener" @click="clickListener" @toggle-flipped="toggleFlippedListener" :number="index + 1"></card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:sidebar>
            <div class="aside">
                <div class="aside-logo">
                    <img src="@/assets/mhfr-logo.svg">
                </div>
                <div class="aside-controls">
                    <div v-if="activeGameCard" class="aside-controls-player">
                        <audio-plyr ref="player" :src="activeGameCard.audioSrc"></audio-plyr>
                    </div>
                    <button class="btn btn-primary btn-block btn-lg" @click="onCheckSelectedGameCards">Auswahl prüfen</button>
                    <button class="btn btn-primary btn-block" @click="onRestartGame">Neues Spiel</button>
                    <button v-if="memoryGame.selectedGameCards.length" class="btn btn-primary btn-block" @click="onUnselectAll">Auswahl aufheben</button>
                </div>
                <div class="aside-info">
                    <div class="aside-info-memory-description" v-if="description && showDescription">
                        {{ description }}
                    </div>
                    <div class="aside-info-card-set" v-if="showCardSetInfo">
                        <p class="mb-0">
                            In diesem Memory sind die folgenden Sets vorhanden:
                        </p>
                        <ul class="mb-0">
                            <li v-for="cardSet in memoryGame.cardSets" :key="cardSet['@id']">
                                {{ cardSet.name }}
                                <template v-if="showNumberOfCardsPerCardSet">
                                    ({{ memoryGame.countCardsOfCardSet(cardSet['@id']) }} Karten)
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="aside-notifications">
                    <vue-snotify></vue-snotify>
                </div>
                <div class="aside-statistics">
                    <div class="aside-statistics-item">
                        <div class="aside-statistics-item-label">Score</div>
                        <div class="aside-statistics-item-value">
                            <CountUp v-model="memoryGame.score"></CountUp>
                        </div>
                    </div>
                    <div class="aside-statistics-item">
                        <div class="aside-statistics-item-label">Duration</div>
                        <div class="aside-statistics-item-value">{{ gameDuration }}</div>
                    </div>
                </div>
            </div>
        </template>
    </sidebar-right-layout>
</template>

<script>
import Card from '@/components/Card';
import CountUp from '@/components/CountUp';
import AudioPlyr from '@/components/AudioPlyr';
import MemoryGame from '@/classes/memory-game';
import SidebarRightLayout from '@/layouts/sidebar-right';

export default {
    components: {
        AudioPlyr,
        CountUp,
        Card,
        SidebarRightLayout,
    },
    props: {
        cardSets: {
            type: Array,
            required: true,
        },
        description: {
            type: String,
            default: null,
        },
        showDescription: {
            type: Boolean,
            default: true,
        },
        showCardSetInfo: {
            type: Boolean,
            default: true,
        },
        showNumberOfCardsPerCardSet: {
            type: Boolean,
            default: true,
        },
        maxNumberOfCardsPerCardSet: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            memoryGame: null,
            selectedCardSetResults: [],
            activeGameCard: null,
        };
    },
    async created() {
        this.memoryGame = new MemoryGame(this.cardSets, {
            maxNumberOfCardsPerCardSet: this.maxNumberOfCardsPerCardSet,
        });
        this.memoryGame.addEventListener('won', this.wonListener);
        this.memoryGame.addEventListener('restart', this.restartListener);
        this.memoryGame.addEventListener('resolveSuccess', this.resolveSuccessListener);
        this.memoryGame.addEventListener('resolveError', this.resolveErrorListener);
    },
    computed: {
        gameDuration() {
            return this.secToTime(this.memoryGame.duration);
        },
    },
    methods: {
        secToTime(timeInSeconds) {
            const pad = function(num, size) { return ('00000' + num).slice(size * -1); },
                time = parseFloat(timeInSeconds).toFixed(3),
                hours = Math.floor(time / 60 / 60),
                minutes = Math.floor(time / 60) % 60,
                seconds = Math.floor(time - minutes * 60);

            return (hours > 0 ? pad(hours, 2) + ':' : '') + pad(minutes, 2) + ':' + pad(seconds, 2);
        },
        onCheckSelectedGameCards() {
            this.selectedCardSetResults = this.memoryGame.checkSelectedGameCards();
        },
        onRestartGame() {
            this.$snotify.info('Neues Spiel starten?', {
                timeout: 5000,
                buttons: [
                    {text: 'Yes', action: () => this.memoryGame.restart(), bold: false},
                    {text: 'No', action: (toast) => this.$snotify.remove(toast.id)},
                ],
            });
        },
        onUnselectAll() {
            this.memoryGame.unselectAll();
        },
        togglePlayListener(gameCard) {
            this.activeGameCard = gameCard;
            this.$nextTick(() => {
                this.$refs.player.togglePlay();
                if (this.$refs.player.isPlaying()) {
                    gameCard.selected = true;
                }
            });
        },
        toggleFlippedListener(gameCard) {
            this.memoryGame.toggleGameCardFlipped(gameCard);
        },
        clickListener(gameCard) {
            this.memoryGame.start();
            if(!gameCard.selected) {
                this.$refs.player.stop();
            }
        },
        wonListener() {
            this.$snotify.success('Spiel gewonnen!');
            this.$confetti.start({
                particles: [
                    {
                        type: 'heart',
                    },
                    {
                        type: 'rect',
                    },
                    {
                        type: 'circle',
                    },
                ],
            });
            setTimeout(() => {
                this.$confetti.stop();
            }, 2000);
        },
        restartListener() {
            this.$snotify.clear();
            this.activeGameCard = null;
        },
        resolveSuccessListener(event) {
            this.$snotify.success(`Set "${event.detail.selectedCardSetResults[0].cardSet.name}" wurde erfolgreich gefunden.`);
        },
        resolveErrorListener(event) {
            const messages = [];
            if(event.detail.selectedCardSetResults.length === 1) {
                const num = event.detail.selectedCardSetResults[0].cardSetCardsLength - event.detail.selectedCardSetResults[0].selectedGameCardsLength;
                const numCardsMessage = (num === 1 ? 'fehlt eine Karte' : `fehlen ${num} Karten`);
                messages.push(`Es ${numCardsMessage} für das Set "${event.detail.selectedCardSetResults[0].cardSet.name}".`);
            }
            else if(event.detail.selectedCardSetResults.length > 1) {
                messages.push(`Es wurden Karten aus ${event.detail.selectedCardSetResults.length} unterschiedlichen Sets ausgewählt.`);
            }
            event.detail.selectedCardSetResults.forEach(gameCardResult => {
                if(gameCardResult.cardSetCardsLength === gameCardResult.selectedGameCardsLength) {
                    messages.push(`Das Set "${gameCardResult.cardSet.name}" wurde gefunden, aber es sind noch zusätzliche Karten aus anderen Sets ausgewählt.`);
                }
            });
            this.$snotify.error(messages.join(' '));
            event.detail.selectedGameCards.forEach(gameCard => {
                gameCard.error = true;
                console.log(gameCard);
                setTimeout(() => {
                    gameCard.error = false;
                    console.log(gameCard);
                }, 3000);
            });
        },
    },
};
</script>

<style scoped lang="scss">
    @import "@/scss/import";

    .overflow-visible {
        overflow: visible;
    }

    .aside-controls-player {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $border-color
    }

    .aside {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    .aside > div {
        padding: 1rem;
    }

    .aside .aside-logo {
        padding-bottom: 0;
    }

    .aside-notifications {
        flex-grow: 1;
    }

    .aside-info-memory-description {
        padding-bottom: 1rem;
        border-bottom: 1px solid $border-color;
        margin-bottom: 1rem;
    }

    .aside div.aside-statistics {
        padding: 0;
        display: grid;
        grid-template-columns: 50% 50%;

        .aside-statistics-item {
            padding: .5rem 1rem;
            border-top: 1px solid $border-color;

            .aside-statistics-item-label {
                font-size: .8rem;
            }

            .aside-statistics-item-value {
                font-family: monospace;
                font-size: 1.3rem;
            }
        }

        .aside-statistics-item + .aside-statistics-item {
            border-left: 1px solid $border-color;
        }
    }
</style>
