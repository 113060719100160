import Vue from 'vue';
import App from './App.vue';
import './scss/base.scss';
import { BootstrapVueIcons } from 'bootstrap-vue';
import Snotify from 'vue-snotify';
import VueConfetti from 'vue-confetti';
import VModal from 'vue-js-modal';

Vue.use(BootstrapVueIcons);
Vue.use(Snotify, {
    global: {
        maxAtPosition: 9999,
        maxOnScreen: 9999,
    },
    toast: {
        bodyMaxLength: 9999,
        timeout: 0,
        icon: false,
        closeOnClick: false,
    },
});
Vue.use(VueConfetti);
Vue.use(VModal);

const elem = document.querySelector('#app');
new Vue({
    render: h => h(App, {
        props: {
            url: elem.dataset.url,
        },
    }),
}).$mount(elem);
