<template>
    <div class="audio-memory">
        <memory-game v-if="memory" v-bind="memory"></memory-game>
        <loading v-else-if="isLoading"></loading>
        <div v-else class="text-center">
            <h4>{{ error || 'Unknown error' }}</h4>
            <div>Pass a JSON endpoint to a memory as data attribute to the Vue component</div>
            <div><code>&lt;div id="app" data-url="https://mh-freiburg.de/path/to/memory.json"&gt;&lt;div&gt;</code> </div>
            <div>
                Or setup a <a href="https://glarean.mh-freiburg.de/git/digiplay/audio-memory/src/branch/master/public/memory.json">memory.json</a> file in this directory manually.
            </div>
            <div>
                If you need support on your local machine use <a href="https://glarean.mh-freiburg.de/git/digiplay/audio-memory/src/branch/master/public/memory.js">memory.js</a> instead.
            </div>
            <div><a href="https://glarean.mh-freiburg.de/git/digiplay/audio-memory">https://glarean.mh-freiburg.de/git/digiplay/audio-memory</a></div>
        </div>
    </div>
</template>

<script>
import MemoryGame from '@/components/MemoryGame';
import Loading from '@/components/Loading';
import { normalizeMemory } from '@/functional/normalizeMemory';

export default {
    components: {
        MemoryGame,
        Loading,
    },
    props: {
        url: {
            default: null,
        },
    },
    data() {
        return {
            error: null,
            isLoading: true,
            memory: null,
        };
    },
    async created() {
        try {
            const response = await fetch(this.url || 'memory.json');
            this.setMemory(await response.json());
        } catch(e) {
            this.error = e;
            if(!this.memory && window.memory) {
                try {
                    this.setMemory(window.memory);
                } catch(error) {
                    this.error = error;
                }
            }
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        setMemory(memory) {
            normalizeMemory(memory);
            if(memory.cardSets.length) {
                this.memory = memory;
            } else {
                throw 'No card sets in this memory';
            }
        },
    },
};
</script>

<style scoped  lang="scss">
    .audio-memory {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
