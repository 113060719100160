<template>
    <div class="loading-component">
        <div class="text-center">
            <loading-spinner></loading-spinner>
            <div class="text-center mt-2">{{ message }}</div>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner';

export default {
    components: {
        LoadingSpinner,
    },
    props: {
        message: {
            type: String,
            default: 'Loading…',
        },
    },
};
</script>

<style scoped>
    .loading-component {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
