import GameCard from '@/classes/game-card';
import { EventEmitter } from '@/classes/event-emitter';
import { sampleSizeWithMaxNumberOfElements } from '@/functional/sampleSizeWithMaxNumberOfElements';

export default class MemoryGame extends EventEmitter {

    constructor(cardSets, options) {
        super();
        this.options = options;
        this.cardSets = [...cardSets];
        this.init();
    }

    init() {
        clearInterval(this.nowInterval);
        this.duration = 0;
        this.gameCards = [];
        this.startDate = null;
        this.endDate = null;
        const cards = this.shuffle([...this.allCardsOfCardSetsMerged]);
        cards.forEach(card => {
            this.gameCards.push(new GameCard(card));
        });
        this.dispatchCustomEvent('initialized', this);
    }

    restart() {
        this.init();
        this.dispatchCustomEvent('restart', this);
    }

    shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    get allCardsOfCardSetsMerged() {
        const cards = [];
        this.cardSets.forEach(cardSet => {
            const cardSetCards = sampleSizeWithMaxNumberOfElements(cardSet.cards, this.options.maxNumberOfCardsPerCardSet);
            cardSetCards.forEach(card => {
                const cardClone = Object.assign({}, card, {
                    cardSet: cardSet['@id'],
                });
                cards.push(cardClone);
            });
        });

        return cards;
    }

    get selectedGameCards() {
        return this.gameCards.filter(gameCard => gameCard.selected);
    }

    findCardSet(id) {
        return this.cardSets.find(cardSet => cardSet['@id'] === id);
    }

    checkSelectedGameCards() {

        if(this.isWon || this.selectedGameCards.length <= 0) {
            return;
        }

        this.selectedGameCards.forEach(gameCard => {
            gameCard.numberOfAttempts += 1;
        });

        const uniqueCardSetIds = this.selectedGameCards.map(gameCard => gameCard.cardSetIri).filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        const selectedCardSetResults = uniqueCardSetIds.map(cardSetIri => {
            const cardSet = this.findCardSet(cardSetIri);
            return {
                cardSetIri,
                cardSet,
                cardSetCardsLength: this.countCardsOfCardSet(cardSetIri),
                selectedGameCardsLength: this.selectedGameCards.filter(gameCard => gameCard.cardSetIri === cardSetIri).length,
            };
        });

        selectedCardSetResults.sort((a, b) => a.selectedGameCardsLength < b.selectedGameCardsLength ? 1 : -1);

        if(selectedCardSetResults.length === 1 && selectedCardSetResults[0].cardSetCardsLength === selectedCardSetResults[0].selectedGameCardsLength) {
            this.resolveSuccessSelectedGameCards(selectedCardSetResults, this.selectedGameCards);
        } else {
            this.resolveErrorSelectedGameCards(selectedCardSetResults, this.selectedGameCards);
        }

    }

    resolveSuccessSelectedGameCards(selectedCardSetResults, selectedGameCards) {
        this.selectedGameCards.forEach(gameCard => {
            gameCard.resolved = true;
        });
        this.dispatchCustomEvent('resolveSuccess', {
            selectedCardSetResults,
            selectedGameCards,
        });
        if(this.isWon) {
            this.endDate = new Date();
            this.dispatchCustomEvent('won', this);
            clearInterval(this.nowInterval);
        }
    }

    resolveErrorSelectedGameCards(selectedCardSetResults, selectedGameCards) {
        this.dispatchCustomEvent('resolveError', {
            selectedCardSetResults,
            selectedGameCards,
        });
    }

    get isWon() {
        return this.gameCards.reduce((accumulator, gameCard) => gameCard.resolved && accumulator);
    }

    get score() {
        return this.gameCards.reduce((accumulator, gameCard) => {
            return accumulator + (gameCard.resolved ? 100 / this.gameCards.length / gameCard.numberOfAttempts : 0);
        }, 0);
    }

    unselectAll() {
        this.selectedGameCards.forEach(gameCard => {
            gameCard.selected = false;
        });
    }

    start() {
        if(this.startDate) {
            return;
        }
        this.startDate = new Date();
        this.nowInterval = setInterval(() => {
            this.duration = ((this.isWon ? this.endDate : new Date()) - this.startDate) / 1000;
        }, 100);
    }

    toggleGameCardFlipped(gameCard) {
        const foundGameCard = this.gameCards.find(elem => elem.id === gameCard.id);
        foundGameCard.toggleFlipped();
    }

    get countCardsOfCardSet() {
        return (cardSetId) => this.gameCards.filter(gameCard => {
            return gameCard.card.cardSet === cardSetId;
        }).length;
    }

}
